@tailwind base;
@tailwind components;
@tailwind utilities;
.translate-enter-active,
.translate-leave-active {
  transform: translateY(0);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 0.3s;
  transition-property: opacity, transform;
}

.translate-enter-from,
.translate-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}

.slide-to-left-enter-active,
.slide-to-left-leave-active {
  @apply transition-transform duration-500 ease-out-expo;
}

.slide-to-left-enter-from,
.slide-to-left-leave-to {
  @apply translate-y-full s:translate-y-0 s:translate-x-[120%];
}

.slide-to-left-enter-to,
.slide-to-left-leave-from {
  @apply translate-y-0 s:translate-x-0;
}

.mapboxgl-ctrl-geocoder {
  --tw-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0px 0px 20px 0px var(--tw-shadow-color);
  width: 100% !important;
  max-width: unset !important;
  border-radius: unset !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow) !important;
}

.mapboxgl-ctrl-geocoder--input {
  @apply block s:pr-0 w-full text-lg;
  height: 56px !important;
  padding-right: 1.5rem !important;
  padding-left: 4rem !important;
  font-family: "sofia-pro", "Helvetica Neue", Arial, sans-serif !important;
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}
.mapboxgl-ctrl-geocoder--input::placeholder {
  font-style: italic !important;
  color: theme("colors.grey.nobilis") !important;
}
.mapboxgl-ctrl-geocoder--input:focus {
  outline: unset !important;
}
@media screen and (min-width: 768px) {
  .mapboxgl-ctrl-geocoder--input {
    padding-right: 0 !important;
  }
}

.mapboxgl-ctrl-geocoder--icon {
  display: none !important;
}