.mapboxgl-ctrl-geocoder {
  --tw-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0px 0px 20px 0px var(--tw-shadow-color);
  width: 100% !important;
  max-width: unset !important;
  border-radius: unset !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow) !important;
}

.mapboxgl-ctrl-geocoder--input {
  @apply block s:pr-0 w-full text-lg;
  height: 56px !important;
  padding-right: 1.5rem !important;
  padding-left: 4rem !important;

  font-family: 'sofia-pro', 'Helvetica Neue', Arial, sans-serif !important;
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;

  &::placeholder {
    font-style: italic !important;
    color: theme('colors.grey.nobilis') !important;
  }

  &:focus {
    outline: unset !important;
  }

  @media screen and (min-width: 768px) {
    padding-right: 0 !important;
  }
}

.mapboxgl-ctrl-geocoder--icon {
  display: none !important;
}
