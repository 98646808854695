.translate-enter-active,
.translate-leave-active {
  transform: translateY(0);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 0.3s;
  transition-property: opacity, transform;
}

.translate-enter-from,
.translate-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}

.slide-to-left-enter-active,
.slide-to-left-leave-active {
  @apply transition-transform duration-500 ease-out-expo;
}

.slide-to-left-enter-from,
.slide-to-left-leave-to {
  @apply translate-y-full s:translate-y-0 s:translate-x-[120%];
}

.slide-to-left-enter-to,
.slide-to-left-leave-from {
  @apply translate-y-0 s:translate-x-0;
}
